import React, { useContext, useEffect, useState } from "react";
import Tabs from "../../components/account/Tabs";
import { useParams } from "react-router-dom";
import NotFound from "../NotFound";
import Breadcrumbs from "../../components/account/Breadcrumbs";
import PowerBI from "../../components/powerBIAuth/PowerBI";
import { UserContext } from "../../context/userContext";
import {
  breadCrumbs,
  brokerAnalyzerTool,
  renewalAnalyzerTool,
} from "../../data/data";
import SelectDeployment from "../../components/modules/SelectDeployment";

const Q3_2024 = ({ solution, deployment }) => {
  const { slug } = useParams();
  const [isSlugCorrect, setIsSlugCorrect] = useState(false);
  const [pageData, setPageData] = useState(null);
  const { setPageTitle } = useContext(UserContext);
  const [crumbs, setCrumbs] = useState(null);
  const [modules, setModules] = useState(null);
  const [deploymentName, setDeploymentName] = useState(
    deployment.slug.split("/")[1]
  );

  useEffect(() => {
    solution[deploymentName].map((module) => {
      if (module?.slug.slice(1) === slug) {
        setModules(solution[deploymentName]);
        setPageData(module);
        return setIsSlugCorrect(true);
      }
    });
  }, [slug]);

  useEffect(() => {
    setPageTitle(`${deployment.title}: ${pageData?.title}`);
    setCrumbs([
      ...breadCrumbs(solution),
      modules?.title !== solution?.title
        ? { title: modules?.title, title: pageData?.title }
        : { title: pageData?.title },
    ]);
  }, [pageData, deployment]);

  return (
    <>
      {/* Timeline Agnostic Template Only */}
      {isSlugCorrect && pageData ? (
        <section className="p-5 pt-0 w-full md:min-h-[35rem]">
          <div className="flex flex-col md:flex-row justify-between">
            <Breadcrumbs crumbs={crumbs} />
            <SelectDeployment
              deployments={solution.deployments}
              selected={[deployment]}
            />
          </div>
          <div className="my-5">
            <Tabs data={modules} deployment={solution.slug + deployment.slug} />
          </div>
          <div className={`w-full pb-10 h-full md:min-h-[35rem]`}>
            {/* Renewals Analyzer Tool */}
            {renewalAnalyzerTool[deploymentName] && (
              <>
                {renewalAnalyzerTool[deploymentName].map(
                  (module, index) =>
                    pageData.title === module.title &&
                    pageData.dashboards && (
                      <PowerBI
                        key={index}
                        reportId={
                          pageData?.dashboards[deploymentName]?.reportId
                        }
                        note={null}
                      />
                    )
                )}
                {pageData.title === "User Guide" &&
                  pageData.title === module.title && (
                    <embed
                      src={pageData?.pdf}
                      className="w-full h-[700px]"
                    ></embed>
                  )}
              </>
            )}

            {/* Borker Analyzer Tool */}
            {brokerAnalyzerTool[deploymentName] && (
              <>
                {brokerAnalyzerTool[deploymentName].map(
                  (module, index) =>
                    pageData.title === module.title &&
                    pageData.dashboards && (
                      <PowerBI
                        key={index}
                        reportId={
                          pageData?.dashboards[deploymentName]?.reportId
                        }
                        note={pageData?.dashboards[deploymentName]?.note}
                      />
                    )
                )}
                {pageData.title === "User Guide" &&
                  pageData.title === module.title && (
                    <embed
                      src={pageData?.pdf}
                      className="w-full h-[700px]"
                    ></embed>
                  )}
              </>
            )}
          </div>
        </section>
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default Q3_2024;
